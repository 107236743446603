import React, { useState } from 'react';
import { Link } from 'gatsby';

import CheckMark from '@heroicons/react/solid/CheckIcon';
import BriefcaseIcon from '@heroicons/react/solid/BriefcaseIcon';
import UserGroupIcon from '@heroicons/react/solid/UserGroupIcon';
import EmojiHappyIcon from '@heroicons/react/solid/EmojiHappyIcon';
import GiftIcon from '@heroicons/react/solid/GiftIcon';
import XIcon from '@heroicons/react/solid/XIcon';

import SignupModalTrigger from './signupModal'


const plans = [
  {
    'name': 'Free',
    'description': 'A free plan to start discovering your target audience and what they discuss on Reddit.',
    'icon': GiftIcon,
    'price': '0',
    'index': 0,
    'cta': 'Sign up for free',
    'features': [
      'Subreddit discovery',
      'Keyword search',
      'Popular topics',
      // 'Keyword tracking',
      // 'Advanced search filters',
    ]
  },
  {
    'name': 'Starter',
    'description': 'Leverage Reddit to ideate startups, validate solutions, research markets, and find customers.',
    'icon': EmojiHappyIcon,
    'price': '29',
    'index': 1,
    'cta': 'Buy Starter',
    'features': [
      // 'Community discovery',
      'Keyword tracking',
      'Advanced search filters',
      'Customer development w/AI',
    ]
  },
  {
    'name': 'Pro',
    'description': 'Explore Reddit like a pro. Discover growing niches, get content insights, save hours daily with AI insights.',
    'popular': true,
    'icon': BriefcaseIcon,
    'price': '59',
    'index': 2,
    'cta': 'Buy Pro',
    'features': [
      'Find patterns w/AI',
      'Exploding subreddits list',
      'Content performance insights',
      'Slack/Discord integration',
    ]
  },
  {
    'name': 'Mega',
    'description': 'Our biggest plan, for high-volume customer research firms, startup studios, or content agencies.',
      // 'popular': true,
    'icon': UserGroupIcon,
    'price': '199',
    'index': 3,
    'cta': 'Buy Mega',
    'features': [
      'Increased AI limits',
      'Shareable reports',
      'Multiple seats (coming soon)',
    ]
  },
  // {
  //   'name': 'Team',
  //   'description': 'Collaborate with your team to explore & keep track of your audiences. Best for high-volume social listening companies or market/content research agencies.',
  //   'comingSoon': true,
  //   'icon': UserGroupIcon,
  //   'price': '149',
  //   'index': 3,
  //   'features': [
  //     'Multiple user seats',
  //     'Shareable reports',
  //   ]
  // }
]



const PriceTable3 = () => {
  const [selectedPlan, setSelectedPlan] = useState(plans[1]);
  return (
    <section className="bg-slate-light font-dm-sans p-6">
      <div className="md:m-auto max-w-default py-20">
        <div className="sm:text-center">
          
          <div className="mt-[18px]">
            <h2 className="mt-4 font-extrabold text-4xl sm:text-5xl mb-2">
              GummySearch Pricing
            </h2>
            <h5 className="text-desktop-subheading text-slate-body">
              Fair pricing for indie hackers, early startups, and growing teams
            </h5>
            <h5 className="font-bold text-sm text-slate-body">
              (33% discount for annual plan)
            </h5>
          </div>
          <div className="mt-[60px]">
            <div className="flex flex-col justify-center md:flex-row">
              <SingleTabs
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
              />
              <MainPanel selectedPlan={selectedPlan} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


const Tab = ({plan, selectedPlan, setSelectedPlan}) => {
  const TabIcon = plan.icon
  return (
    <div
      onClick={() => setSelectedPlan(plan)}
      className={`sm:w-[355px] flex-1 cursor-pointer bg-gray-800 rounded-3xl p-4 ${
        plan.name == selectedPlan.name ? 'border-[3px] border-slate-blue' : 'border-[3px] border-transparent'
      }`}
    >
      <div className="flex">
        <div>
          <span className="flex items-center justify-center w-10 h-10 rounded-full bg-slate-orange">
            <TabIcon className="h-6 w-6" />
          </span>
        </div>
        <div className="w-auto ml-4 text-left">
          <div className="text-xl font-medium text-slate-headline flex items-center">
            <div className="flex items-center">
              {plan.name}
              {plan.popular ? (
                <span className="p-1 px-2 rounded-full bg-gray-600 text-white text-xs ml-2">most popular</span>
              ) : ''}
            </div>
            <div className="ml-auto font-bold">${plan.price}/mo</div>
          </div>
          <p className="text-[15px] mt-2 text-md text-slate-body">{plan.description}</p>
        </div>
      </div>
    </div>
  )
}

const SingleTabs = ({ selectedPlan, setSelectedPlan }) => (
  <div className="flex flex-col space-y-3 md:mr-[15px]">
    {plans.map(p => (
      <Tab key={p.name} plan={p} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
    ))}
  </div>
);

const MainPanel = ({ selectedPlan }) => {
  const TabIcon = selectedPlan.icon;

  var allFeatures = [];
  var currentPlanFeatures = [];
  plans.forEach(p => {
    p.features.forEach(f => {
      allFeatures.push(f)

      if (selectedPlan.index >= p.index){
        currentPlanFeatures.push(f)
      }
    })
  });

  return (
    <div className="p-8 pb-4 md:ml-[15px] bg-gray-800 rounded-3xl sm:w-[355px] mt-6 md:mt-0 flex flex-col">
      <div className="flex items-center justify-center w-12 h-12 m-auto rounded-full bg-slate-orange">
        <TabIcon className="h-8 w-8" />
      </div>
      <p className="mt-4 text-xl font-medium capitalize text-slate-headline">
        {selectedPlan.name} Plan - ${selectedPlan.price}/mo
      </p>
      <div className="mt-7 text-left mb-8">
        <ul>
          {allFeatures.map(f => (
            <li key={f} className={`flex items-center w-full mb-2 ${currentPlanFeatures.includes(f) ? '' : 'opacity-20'}`}>
              {currentPlanFeatures.includes(f) ? (
                <CheckMark className="w-5 h-5 mr-2" />
              ) : (
                <XIcon className="w-5 h-5 mr-2" />
              )}
              <span className="w-full text-desktop-paragraph text-slate-body">
                {f}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-auto">
        <SignupModalTrigger initiator="homepage-pricing-plan" detail={selectedPlan.name}>
          <div className="flex items-center cursor-pointer font-bold justify-center w-full py-4 text-white rounded-lg bg-green-500 hover:opacity-90">
            <span>{selectedPlan.cta}</span>
          </div>
        </SignupModalTrigger>
        {/*<div className="py-4 pt-4 mx-auto text-sm opacity-50">No CC required</div>*/}
      </div>
    </div>
  );
}

export default PriceTable3;
